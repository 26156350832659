import * as React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

//page building utils
import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

const NotFoundPage = ({data}) => (
  <Layout>
    <Seo title="404: Not found" />

    <ImgBackground image={data.mainhero}>
      <div className="h-screen w-full flex items-center justify-center">
      <TitleBox title="Page not found"><p className="text-white text-xl">Sorry, you just tried a url that doesn&#39;t exist... check for typos in the url, or perhaps try one of the options in the main menu bar</p></TitleBox>
      </div>
    </ImgBackground>

  </Layout>
)

export const query = graphql`
  {
    mainhero: file(
      relativePath: { eq: "backgrounds/renewables-sunset.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
  }
`

export default NotFoundPage
